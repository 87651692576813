import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const Dedicados = () => (
  <Layout>
    <Seo title="Servidores Dedicados" />
    <Container className="text-center pb-6 pt-6">
      <h1>Servidores Dedicados</h1>
      <p>
        Sientase libre de{" "}
        <a href="https://portal.puntohost.com/contact.php">
          enviarnos un email
        </a>{" "}
        con los requerimientos o caracteristicas que desea.
      </p>
      {/* TODO: Publicar 3 o más servidores dedicados. */}
    </Container>
  </Layout>
)

export default Dedicados
